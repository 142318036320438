import * as React from "react"
import SharedHeader from "../../../../components/general/components/SharedHeader"
import HighFrequencyDetailsPage from "../../../../components/robot/pages/HighFrequencyDetailsPage"


// markup
const HighFrequencyDetails = (props) => {
    return (
        <React.Fragment>
            <SharedHeader />
            <HighFrequencyDetailsPage {...props} />
        </React.Fragment>
    )
}

export default HighFrequencyDetails
